<template>
  <div class="mb-4">
    <b-alert :variant="'primary'" show class="cursor-pointer">
      <div class="alert-body">
        <strong>
          <feather-icon icon="BookIcon" class="mr-25" />
          Monitoring is more than just watching—it's proactive engagement. With
          real-time visitor tracking, our agents are always one step ahead,
          ensuring safety and smooth operations.
        </strong>
      </div>
    </b-alert>
    <b-list-group class="mb-1">
      <b-list-group-item class="d-flex">
        <span class="mr-1">
          <feather-icon icon="ActivityIcon" size="16" />
        </span>
        <span>
          Demi menjaga
          <b-badge variant="danger" class="badge-glow button">
            privasi dan keamanan
          </b-badge>
          data pengguna, proses unggah KTP saat ini tidak tersedia
          <strong><i>sementara</i></strong>
          sesuai dengan kebijakan privasi yang berlaku.
        </span>
      </b-list-group-item>

      <b-list-group-item class="d-flex">
        <span class="mr-1">
          <feather-icon icon="KeyIcon" size="17" />
        </span>
        <span>
          Without the exact key, <i>brute-forcing</i> My data would take
          <strong>billions of years to be unlocked with current computing
            power</strong>, so it's safe on any network. <b-badge variant="success" class="badge-glow button">
            <feather-icon icon="DatabaseIcon" />

            Transmission data

          </b-badge> is fully
          protected.
        </span>
      </b-list-group-item>

      <!-- <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon icon="AlertTriangleIcon" size="16" />
                </span>
                <span>

                </span>
              </b-list-group-item> -->
    </b-list-group>
    <b-row class="match-height">
      <b-col cols="12" md="12">
        <validation-observer ref="yoVisitorRule" tag="form">
          <b-form ref="formListQR" class="repeater-form">
            <b-row>
              <b-col cols="12">
                <!-- LOOPING FIELD -->
                <b-card no-body class="border-primary repeater-form py-1 px-1"
                  v-for="(item, index) in form.itemPengunjung" :id="item.fe_id" :key="item.fe_id" ref="rowPengunjung">
                  <b-row>
                    <b-col lg="1" md="1" cols="6">
                      <b-badge pill variant="info" class="mt-0">
                        Data {{ index + 1 }}
                      </b-badge>
                    </b-col>
                    <!-- hanya desktop | mobile hide -->
                    <b-col lg="10" md="10" cols="3" class="d-none d-md-block">
                      <hr />
                    </b-col>
                    <b-col lg="1" md="1" cols="6">
                      <b-badge pill variant="danger" class="badge-glow button" @click="removeItemPengunjung(index)">
                        <feather-icon icon="XIcon" :style="{ cursor: 'pointer' }" />
                        Hapus
                      </b-badge>
                    </b-col>

                    <b-col lg="4" md="4" cols="12">
                      <b-form-group :label="`Badge`" :label-for="`vi-forpe-badge_id-${index}`">
                        <validation-provider #default="{ errors }" :name="`Badge`" :vid="`vi-forpe-badge_id-${index}`"
                          rules="required">
                          <v-select :id="`vi-forpe-badge_id-${index}`" v-model="item.badge_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="badgeName"
                            :reduce="(option) => option.value" :selectable="(option) => !option.value.includes('select_value')
                              " label="text" item-value="value" item-text="text" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="4" cols="12">
                      <b-form-group :label="`Kunjungan ke Perusahaan`" :label-for="`vi-forpe-visitor_cat_id-${index}`">
                        <validation-provider #default="{ errors }" :name="`Kunjungan ke Perusahaan`"
                          :vid="`vi-forpe-visitor_cat_id-${index}`" rules="required">
                          <v-select :id="`vi-forpe-visitor_cat_id-${index}`" v-model="item.visitor_cat_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="categoryName"
                            :reduce="(option) => option.value" :selectable="(option) => !option.value.includes('select_value')
                              " label="text" item-value="value" item-text="text">
                            <template #option="{ color, text }">
                              <b-spinner small type="grow" :variant="color" />
                              <span class="ml-50 align-middle">
                                <strong>{{ text }}</strong></span>
                            </template>

                            <template #selected-option="{ color, text }">
                              <b-spinner small type="grow" :variant="color" />
                              <span class="ml-50 align-middle">
                                <strong>{{ text }}</strong></span>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="4" cols="12">
                      <b-form-group :label="`Nomor Whatsapp * (Ex: 8155 7777 33)`" :label-for="`phone-${index}`">
                        <validation-provider #default="{ errors }" :name="`Nomor Whatsapp *`" :vid="`phone-${index}`"
                          rules="required">
                          <b-input-group>
                            <b-input-group-prepend is-text>ID (+62)</b-input-group-prepend>
                            <cleave :id="`phone-${index}`" v-model="item.phone" class="form-control" :raw="false"
                              :options="options.phone" placeholder="8155 7777 33"
                              :state="errors.length > 0 ? false : null" />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col lg="12" md="12" cols="12">
                      <b-form-group :label-for="`file_ktp-${index}`">
                        <validation-provider
                          #default="{ errors }"
                          :name="`File KTP*`"
                          :vid="`file_ktp-${index}`"
                          rules="required"
                        >
                          <b-form-file
                            :ref="(el) => (refInputEl[index] = el)"
                            v-model="item.no_ktp_file"
                            placeholder="File KTP..."
                            accept=".jpeg, .jpg, .png"
                            @input="() => inputImageRenderer(item, index)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div v-if="item.extractedNik">
                        <b-card
                          border-variant="success"
                          header-border-variant="success"
                          header-text-variant="success"
                        >
                          <small
                            >System berhasil membaca
                            <strong>Nomor NIK : </strong>
                            <span
                              class="cursor-pointer"
                              v-b-tooltip.hover
                              title="Click to copy"
                              @click="copyText(item.extractedNik)"
                            >
                              {{ item.extractedNik }}
                              <feather-icon icon="CopyIcon" /> </span
                            >, jika benar maka Click nomor NIK (copy) dan paste
                            ke kolom pengisian <strong>Nomor KTP</strong>.
                          </small>
                        </b-card>
                      </div>
                    </b-col> -->

                    <b-col lg="3" md="3" cols="12">
                      <b-form-group :label="`Nomor KTP *`" :label-for="`no_ktp-${index}`">
                        <validation-provider #default="{ errors }" :name="`Nomor KTP *`" :vid="`no_ktp-${index}`"
                          rules="required|min:16">
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <feather-icon icon="KeyIcon" />
                            </b-input-group-prepend>
                            <cleave :id="`no_ktp-${index}`" v-model="item.no_ktp" class="form-control" :raw="true"
                              :options="options.noKTP" placeholder="Nomor KTP" />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="3" md="3" cols="12">
                      <b-form-group :label="`Nama Lengkap *`" :label-for="`full_name-${index}`">
                        <validation-provider #default="{ errors }" :name="`Nama Lengkap *`" :vid="`full_name-${index}`"
                          rules="required">
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <feather-icon icon="UserIcon" />
                            </b-input-group-prepend>
                            <b-form-input v-uppercase :id="`full_name-${index}`" v-model="item.full_name"
                              :state="errors.length > 0 ? false : null" placeholder="Nama Lengkap" />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="6" md="6" cols="12">
                      <b-form-group :label="`Alamat *`" :label-for="`address-${index}`">
                        <validation-provider #default="{ errors }" :name="`Alamat *`" :vid="`address-${index}`"
                          rules="required">
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <feather-icon icon="MapPinIcon" />
                            </b-input-group-prepend>
                            <b-form-input v-uppercase :id="`address-${index}`" v-model="item.address"
                              :state="errors.length > 0 ? false : null"
                              placeholder="C5QH+98X, Rw., Rw. Makmur, Kec. Palaran, Kota Samarinda, Kalimantan Timur 75251" />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="6" md="6" cols="12">
                      <b-form-group :label="`Asal Perusahaan / Instansi *`" :label-for="`from_company-${index}`">
                        <validation-provider #default="{ errors }" :name="`Asal Perusahaan / Instansi *`"
                          :vid="`from_company-${index}`" rules="required">
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <feather-icon icon="MapIcon" />
                            </b-input-group-prepend>
                            <b-form-input v-uppercase :id="`from_company-${index}`" v-model="item.from_company"
                              :state="errors.length > 0 ? false : null" placeholder="Asal Perusahaan / Instansi" />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="6" md="6" cols="12">
                      <b-form-group :label="`Ket. / Keperluan *`" :label-for="`ket-${index}`">
                        <validation-provider #default="{ errors }" :name="`Ket. / Keperluan *`" :vid="`ket-${index}`"
                          rules="required">
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <feather-icon icon="CoffeeIcon" />
                            </b-input-group-prepend>
                            <b-form-input v-uppercase :id="`ket-${index}`" v-model="item.ket"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Example: Monthly Meeting K3A, Daily Checking, etc" />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
                <!-- END OF LOOPING FIELD -->
              </b-col>

              <b-col lg="12" md="12" cols="12">
                <b-card>
                  <b-button size="md" variant="outline-primary" block @click="repeatePengunjung">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Tambah Pengunjung</span>
                  </b-button>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col cols="12" md="12">
        <b-card>
          <validation-observer ref="yoScheduleRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> C </b-badge> Schedule IN
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Planning.</strong>
                </small>
              </b-col>
              <b-col>
                <!-- Calendar for selecting the date -->
                <b-form-group label="">
                  <validation-provider #default="{ errors }" name="Schedule IN Date" rules="required">
                    <b-calendar v-model="form.schedule_in_date" block locale="id-ID" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Time picker for selecting the time -->
                <b-form-group label="">
                  <validation-provider #default="{ errors }" name="Schedule IN Time" rules="required">
                    <b-form-timepicker v-model="form.schedule_in_time" now-button reset-button locale="id-ID"
                      :hour12="false" class="mt-2" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <strong>
                  <br />
                  <i>" Every scan tells a story of protection and control. "</i>
                </strong>
                <!-- <p>Selected Date: {{ form.schedule_in_date }}</p>
                <p>Selected Time: {{ form.schedule_in_time }}</p> -->
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12" md="12">
        <b-card>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                <b-badge pill variant="primary"> D </b-badge> Short Summary List
                Data QR
              </h5>
              <small class="text-muted">
                <strong>Akan mengirimkan kode QR Ke masing-masing pengunjung secara
                  queue</strong>
              </small>
            </b-col>
          </b-row>
          <YoGoodTable :fields="tableColumns" :data="tableDataWatch"
            :paginationOptions="{ enabled: true, perPage: 10 }" />
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-form-checkbox v-model="agree">
            Saya telah membaca dan menyetujui
            <b-link href="https://docs.salpalaran.com/visitor/privacy_policy.html" target="_blank">Kebijakan
              Privasi</b-link>.
          </b-form-checkbox>
        </b-card>
      </b-col>

      <b-col cols="12" v-if="agree">
        <b-button :disabled="loadingSaveData" block @click="formSubmitted" variant="primary">
          <b-spinner v-if="loadingSaveData" small />
          <feather-icon v-else icon="CheckCircleIcon" />
          Simpan data dan Kirim QRCode Ke Whatsapp
          {{ form.phone ? `+62 ${form.phone}` : `` }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { encrypt } from '@core/utils/aes-256-cbc';
import Cleave from 'vue-cleave-component';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us';

import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BBadge,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BAvatar,
  BCalendar,
  BFormTimepicker,
  BImg,
  BAlert
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import useJwt from '@/auth/jwt/useJwt';
import Tesseract from 'tesseract.js';
import { v4 as uuidv4 } from 'uuid';
import YoGoodTable from '@/views/components/YoGoodTableKhusus.vue';
import { reactive } from '@vue/composition-api';

export default {
  components: {
    BAlert,
    YoGoodTable,
    BButton,
    BFormTimepicker,
    BCalendar,
    BBadge,
    Cleave,
    BImg,
    BAvatar,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BInputGroup,
    BInputGroupPrepend,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback
  },
  data() {
    return {
      agree: false,
      /* good table */
      tableColumns: [
        {
          label: 'Badge ( Perusahaan / Instansi )',
          field: 'badge',
          width: '350px'
        },
        { label: 'Nama / Whatsapp', field: 'full_name', width: '200px' },
        { label: 'Ket. / Keperluan', field: 'ket', width: '200px' }
      ],
      tableData: [
        // {
        //   badge: '[ A.1 ] Tamu ( Visitor ) - Umum',
        //   nomor_whatsapp: '628123456789',
        //   ktp: '7409011503990011',
        //   phone: '08154571281',
        //   full_name: 'Yogi Arif Widodo',
        //   alamat: 'Jl. Gerbang Dayaku No 1, Jakarta',
        //   perusahaan: 'PT Sarana Abadi Lestari',
        //   ket: 'Kunjungan untuk pertemuan dinas di bali'
        // },
        // {
        //   badge: '[ A.2 ] Awak 2 Truck',
        //   nomor_whatsapp: '628987654321',
        //   ktp: '6409011503990022',
        //   phone: '08154571382',
        //   full_name: 'Arif Setiawan',
        //   alamat: 'Jl. Raya Industri No 5, Bekasi',
        //   perusahaan: 'PT Transportasi Jaya',
        //   ket: 'Pengiriman barang untuk proyek di Jakarta'
        // },
        // {
        //   badge: '[ B ] Identitas / Karyawan / Rutinitas',
        //   nomor_whatsapp: '628112233445',
        //   ktp: '6409011503990033',
        //   phone: '08154571483',
        //   full_name: 'Dina Pratama',
        //   alamat: 'Jl. Merdeka No 45, Bandung',
        //   perusahaan: 'PT Bank Negara',
        //   ket: 'Karyawan untuk pengurusan administrasi'
        // },
        // {
        //   badge: '[ C ] Pekerja Harian',
        //   nomor_whatsapp: '628334455667',
        //   ktp: '6409011503990044',
        //   phone: '08154571584',
        //   full_name: 'Budi Santoso',
        //   alamat: 'Jl. Pasar No 12, Surabaya',
        //   perusahaan: 'PT Pekerja Mandiri',
        //   ket: 'Pekerjaan harian untuk proyek konstruksi'
        // },
        // {
        //   badge: '[ D ] Pengurus Agent BBM',
        //   nomor_whatsapp: '628556677889',
        //   ktp: '7409011503990055',
        //   phone: '08154571685',
        //   full_name: 'Siti Aminah',
        //   alamat: 'Jl. BBM Raya No 21, Medan',
        //   perusahaan: 'PT Pertamina',
        //   ket: 'Pengurusan distribusi bahan bakar minyak'
        // }
      ],

      loadingSaveData: false,
      badge: [
        {
          id: 1,
          name: 'Tamu ( Visitor )',
          code: 'A.1',
          desc: 'Umum'
        },
        {
          id: 2,
          name: 'Awak 2 Truck',
          code: 'A.2',
          desc: ''
        },
        {
          id: 3,
          name: 'Identitas / Karyawan / Rutinitas',
          code: 'B',
          desc: ''
        },
        {
          id: 4,
          name: 'Pekerja Harian',
          code: 'C',
          desc: ''
        },
        {
          id: 5,
          name: 'Pengurus Agent BBM',
          code: 'D',
          desc: ''
        }
      ],
      no_ktp_file: {},
      options: {
        phone: {
          // phone: true,
          // phoneRegionCode: 'ID',
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4]
        },
        noKTP: {
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4, 4],
          uppercase: true
        }
      },
      /*
      visitor_public --> id badge_id
      visitor_private --> id badge_id

      ----------------
      ( Reuse QRCode update date_expired and status )
      ( sys_auto_renew = true kalau badge_id = 3 = Reuse QRCode )
      ----------------

      visitor_qrcode --> id model model_id qrcode_img status date_expired sys_auto_renew

      visitor_count --> id id_visitor_qrcode clock_in clock_out diff_time

      ----------------
      index agent
      vw_visitor_qrcode_agent --> id qrcode_img status expired visitor_private ( personal information )

      index user
      vw_visitor_qrcode_user -->  id qrcode_img status expired visitor_public ( personal information )
      ----------------
      */

      required,
      email,
      categoryName: [
        {
          value: '1',
          text: 'Sarana Abadi Lestari (SAL)',
          short: 'SAL',
          color: 'success'
        },
        {
          value: '2',
          text: 'Palaran Indah Lestari (PIL)',
          short: 'PIL',
          color: 'success'
        },
        {
          value: '3',
          text: 'Sarana Utama Lestari (SUL)',
          short: 'SUL',
          color: 'success'
        },
        {
          value: '4',
          text: 'Sarana Palaran Lestari (SPL)',
          short: 'SPL',
          color: 'success'
        },
        {
          value: '5',
          text: 'Pertamina Patra Niaga (PATRA)',
          short: 'PATRA',
          color: 'success'
        },
        // {
        //   value: '6',
        //   text: 'Triputra Energi Megatara (TEM)',
        //   short: 'TEM',
        //   color: 'success'
        // },
        {
          value: '7',
          text: '(SLB)',
          short: 'SLB',
          color: 'success'
        },
        {
          value: '8',
          text: 'Palaran Energi Semesta (PES)',
          short: 'PES',
          color: 'success'
        }
      ],
      badgeName: [
        { color: '', value: 'select_value', text: 'Pilih Badge' },
        // {
        //   color: 'primary',
        //   value: '1',
        //   text: '[ A.1 ] Tamu ( Visitor ) - Umum'
        // },
        {
          color: 'success',
          value: '3',
          text: '[ B ] Identitas / Karyawan / Rutinitas'
        },
        {
          color: 'warning',
          value: '4',
          text: '[ C ] Pekerja Harian'
        },
        {
          color: 'success',
          value: '5',
          text: '[ D ] Pengurus Agent BBM'
        },
        {
          color: 'primary',
          value: '2',
          text: '[ A.2 ] Awak 2 Truck'
        },
      ],

      apiEndpoint: null,
      fields: [
        {
          key: 'badge_id',
          label: 'Badge',
          sortable: true,
          input: {
            type: 'select',
            options: [
              {
                label: '[ A.1 ] Tamu ( Visitor ) - Umum',
                value: '1'
              },
              {
                label: '[ A.2 ] Awak 2 Truck',
                value: '2'
              },
              {
                label: '[ B ] Identitas / Karyawan / Rutinitas',
                value: '3'
              },
              {
                label: '[ C ] Pekerja Harian',
                value: '4'
              },
              {
                label: '[ D ] Pengurus Agent BBM',
                value: '5'
              }
            ],
            reduce({ value }) {
              return value;
            }
          },
          searchModel: 'badge_id',
          search: {
            type: 'text'
          }
        },
        {
          key: 'phone',
          label: 'Nomor Whatsapp',
          placeholder: '815xxx',
          sortable: true,
          input: { type: 'number-prepend', prepend: '+62' },
          searchModel: 'capacity',
          search: {
            type: 'number'
          }
        },
        {
          key: 'ktp',
          label: 'KTP',
          placeholder: 'xxxxxxxxx',
          sortable: true,
          input: { type: 'number' },
          searchModel: 'ktp',
          search: {
            type: 'number'
          }
        },
        {
          key: 'full_name',
          label: 'Nama Lengkap',
          placeholder: 'Nama Lengkap',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'full_name',
          search: {
            type: 'text'
          }
        },
        {
          key: 'address',
          label: 'Alamat',
          placeholder: 'JL Palaran Indah Lestari',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'address',
          search: {
            type: 'text'
          }
        },
        {
          key: 'from_company',
          label: 'Asal Perusahaan / Instansi',
          placeholder: 'Asal Perusahaan / Instansi',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'from_company',
          search: {
            type: 'text'
          }
        }
      ]
    };
  },
  setup() {
    const form = reactive({
      itemPengunjung: [
        {
          fe_id: 1,
          extractedNik: null,
          no_ktp_file: null,
          full_name: null
        }
      ],
      nextItemPengunjungID: 2,
      /* personal informations */
      no_ktp_file: '',
      no_ktp: '',
      full_name: '',
      email: '',
      phone: '',
      badge_id: '',
      twitter: '',
      facebook: '',
      instagram: '',
      linkedin: '',
      schedule_in_date: '',
      schedule_in_time: '',
      uuid: uuidv4(),
      ket: ''
    });
    const refInputEl = ref([]); // Initialize empty arrays for refs
    const refPreviewEl = ref([]);

    // Set up image renderer for each item
    const inputImageRenderer = (item, index) => {
      const inputElement = refInputEl.value[index]; // Get the current file input ref
      if (!inputElement) return; // Ensure the input element is present
      console.log(`${item}-inputImageRenderer${index}`);

      const { inputImageRenderer: renderImage } = useInputImageRenderer(
        inputElement, // Pass the input ref element
        (base64) => {
          const previewElement = refPreviewEl.value[index]; // Get the preview image ref
          if (previewElement) {
            previewElement.src = base64;
          }
          extractText(base64, item); // Extract NIK for the current item
        }
      );

      renderImage(); // Invoke the image renderer
    };

    // Extract NIK logic
    const extractText = (base64Image, item) => {
      console.log(`extracted for ${item}`);
      Tesseract.recognize(
        base64Image,
        'eng', // Use 'ind' for Indonesian text if necessary
        {
          logger: (m) => {
            console.log(m);
          }
        }
      )
        .then(({ data: { text: extractedText } }) => {
          const cleanedText = extractedText
            .replace(/[^\w\s:]/g, ' ')
            .replace(/\s+/g, ' ')
            .trim();

          const nikMatch = cleanedText.match(/NIK\s*:\s*(\w+)/i);

          if (nikMatch && nikMatch[1]) {
            let nik = nikMatch[1];
            if (nik[0] === 'b') {
              nik = '6' + nik.slice(1);
            }
            item.extractedNik = nik; // Set extracted NIK on the item
          } else {
            console.log('NIK not found');
            item.extractedNik = ''; // Clear NIK if not found
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    return {
      form,
      inputImageRenderer,
      refInputEl,
      refPreviewEl
    };
  },
  computed: {
    tableDataWatch() {
      return this.form.itemPengunjung.map((item) => {
        return {
          ...item,
          badge: this.badgeName.find((d) => d.value === `${item.badge_id}`)
            ?.text,
          cat: this.categoryName.find((d) => d.value === `${item.visitor_cat_id}`)
            ?.short,
          perusahaan: item.from_company
        };
      });
    },
    newDataTemplate() {
      return {
        // full_name: '',
        // no_ktp: '',
        // phone: '',
        // address: ''
      };
    }
  },
  methods: {
    /* start of of looping field */
    repeatePengunjung() {
      this.form.itemPengunjung.push({
        fe_id: (this.form.nextItemPengunjungID +=
          this.form.nextItemPengunjungID)
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowPengunjung[0].offsetHeight);
      });
    },
    removeItemPengunjung(index) {
      this.form.itemPengunjung.splice(index, 1);
      this.trTrimHeight(this.$refs.rowPengunjung[0].offsetHeight);
    },
    /* end of looping field */

    resolveFetchResponse(response) {
      return response.data.dm_river_boat_hms;
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      };
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          });
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          });
        }
      );
    },
    async formSubmitted() {
      try {
        await this.validationD().then(async () => {
          await this.validationC().then(async () => {
            this.saveData();
          });
        });
      } catch (error) {
        console.log('errors', error);
      }
    },
    async saveData() {
      try {
        this.loadingSaveData = true;

        // Create the payload by mapping over itemPengunjung and formatting the phone numbers
        const payload = this.form.itemPengunjung.map((item) => {
          let phoneNumber = item.phone.trim().replace(/\s+/g, '');
          if (phoneNumber.startsWith('0')) {
            phoneNumber = phoneNumber.slice(1);
          }
          const formattedPhone = `62${phoneNumber}`;
          return {
            ...item,
            phone: formattedPhone,
            token: this.$store.state.visitor.tokenNotification,
            model: 'visitor_private',
            schedule_in_date: this.form.schedule_in_date,
            schedule_in_time: this.form.schedule_in_time,
            uuid: uuidv4()
          };
        });

        // Send the payload to the visitor_private API
        const result = await useJwt.http.post('visitor_private/', {
          sal_protection: encrypt(payload)
        });
        const { visitor_private } = result.data;
        console.log('visitor_private', visitor_private);
        console.log('visitor_private', visitor_private[0]);

        // Loop through itemPengunjung and upload each corresponding KTP file
        for (let i = 0; i < this.form.itemPengunjung.length; i++) {
          const item = this.form.itemPengunjung[i];
          const formData = new FormData();

          // Append the KTP file to FormData
          formData.append('file', item.no_ktp_file);

          // Upload the KTP file for each item
          await useJwt.http.put(
            // `file_storage_ktp/${visitor_private[i].id}/visitor_private/${item.no_ktp}`,
            `file_storage_ktp/${visitor_private[i].id}/visitor_private/0`,
            formData
          );
        }

        // Success notification
        this.$alert({
          title: 'QRCode Telah dikirim ke Whatsapp Kamu',
          icon: 'SaveIcon',
          variant: 'success'
        });

        // Store success data in Vuex
        this.$store.commit('visitor/SET_SUCCESS_DATA', {
          full_name: 'this.form.full_name',
          phone: 'this.form.phone'
        });

        // Navigate to the success page
        this.$router.push({ path: '/planning/create-form-khusus/success' });
      } catch (error) {
        this.loadingSaveData = false;
        console.error('Error occurred:', error);
        this.$alert({
          title: 'Tidak berhasil melakukan aksi',
          icon: 'SaveIcon',
          variant: 'danger'
        });
      } finally {
        this.loadingSaveData = false;
      }
    },
    validationC() {
      return new Promise((resolve, reject) => {
        this.$refs.yoScheduleRule.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            this.$alert({
              title: 'Form Wajib diisi',
              icon: 'SaveIcon',
              variant: 'danger'
            });
            reject();
          }
        });
      });
    },
    validationD() {
      return new Promise((resolve, reject) => {
        this.$refs.yoVisitorRule.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            this.$alert({
              title: 'Form Wajib diisi',
              icon: 'SaveIcon',
              variant: 'danger'
            });
            reject();
          }
        });
      });
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>