<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="fields"
      :rows="data"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :select-options="selectOptions"
      :pagination-options="paginationOptions"
    >
      <template
        v-slot:table-row="props"
      >
        <!-- Dynamic rendering for custom columns -->
        <span v-if="props.column.field === customFields.avatarField">
          <b-avatar :src="props.row[customFields.avatarField]" class="mx-1" />
          <span class="text-nowrap">{{ props.row[customFields.nameField] }}</span>
        </span>

        <!-- start if custom field -->
        <span v-else-if="props.column.field === 'badge'">
          <b-badge pill variant="light-primary">{{ props.row.badge }} <b-badge pill variant="primary">{{ props.row.cat }}</b-badge></b-badge>
          <div class="divider">
            <div class="divider-text">
              <feather-icon
                icon="MapPinIcon"
                class="align-items-center"
              />
              <span @click="copyText(props.row.perusahaan)">
                <strong> {{ props.row.perusahaan }}</strong>
              </span>
            </div>
          </div>
          <!-- {{ props.row.perusahaan }} -->
        </span>
        <span v-else-if="props.column.field === 'full_name'">
          <feather-icon icon="UserIcon" class="align-items-center" />
          {{ props.row.full_name }}
          <div class="divider">
            <div class="divider-text">
              <feather-icon icon="PhoneCallIcon" class="align-items-center" />
              {{ props.row.phone }}
            </div>
          </div>
        </span>
        <span v-else-if="props.column.field === 'ket'">
          {{ props.row.ket }}

        </span>
        <!-- end of custom field -->

        <span v-else-if="props.column.field === customFields.statusField">
          <b-badge :variant="statusVariant(props.row[customFields.statusField])">
            {{ props.row[customFields.statusField] }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === customFields.actionField">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item @click="$emit('edit', props.row)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$emit('delete', props.row)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template v-slot:pagination-bottom="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">Showing 1 to</span>
            <b-form-select
              v-model="pageLength"
              :options="pageOptions"
              class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap">of {{ props.total }} entries</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  name: 'YoGoodTable',
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    customFields: {
      type: Object,
      default: () => ({
        avatarField: 'avatar',
        nameField: 'fullName',
        statusField: 'status',
        actionField: 'action',
      })
    },
    paginationOptions: {
      type: Object,
      default: () => ({
        enabled: true,
        perPage: 5
      })
    },
    selectOptions: {
      type: Object,
      default: () => ({
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true
      })
    },
    pageOptions: {
      type: Array,
      default: () => ['3', '5', '10']
    }
  },
  data() {
    return {
      pageLength: 5,
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
      }
      return status => statusColor[status]
    },
    direction() {
      return store.state.appConfig.isRTL
    }
  }
}
</script>

<style scoped>
/* Add scoped styles if needed */
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>